import * as React from "react"
import clsx from "clsx"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Box from "@material-ui/core/Box"

import toTime from "../../helperFunc/toTime"
import priceFormat from "../../helperFunc/priceFormat"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  oneLine: { display: "flex" },
  firstItemBold: {
    lineHeight: "1.1rem",
    fontWeight: 700,
    fontSize: "0.9rem",
  },

  firstItemBoldTotal: {
    marginTop: "1rem",
    lineHeight: "1.1rem",
    fontWeight: 700,
    fontSize: "0.9rem",
  },
  secondItemTotal: {
    lineHeight: "2.2rem",
    fontSize: "1.35rem",
  },

  secondItemTotalCrossed: {
    lineHeight: "2.2rem",
    fontSize: "1.35rem",
    textDecoration: "line-through",
    marginRight: "0.5rem",
  },

  secondItem: {
    lineHeight: "1.1rem",
    marginLeft: "0.5rem",
    fontSize: "0.9rem",
  },

  secondItemBold: {
    lineHeight: "1.1rem",
    marginLeft: "0.5rem",
    fontSize: "0.9rem",
    fontWeight: 700,
  },
  secondItemCrossed: {
    lineHeight: "1.1rem",
    marginLeft: "0.5rem",
    fontSize: "0.9rem",
    textDecoration: "line-through",
  },
  standard: {
    lineHeight: "1.1rem",
    fontSize: "0.9rem",
  },

  price: {
    marginTop: "0.25rem",
    fontSize: "0.9rem",
  },

  priceCrossed: {
    marginTop: "0.25rem",
    fontSize: "0.9rem",
    textDecoration: "line-through",
    marginRight: "0.5rem",
  },

  divider: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },

  descriptionTextBox: {
    lineHeight: "0.5rem",
    marginTop: "0.1rem",
  },

  descriptionText: {
    fontSize: "0.8rem",
    lineHeight: "0.8rem",
    display: "inline-block",
    marginRight: "0.2rem",
  },

  descriptionTextBold: {
    lineHeight: "0.8rem",
    display: "inline-block",
    fontWeight: 500,
  },

  itemBox: {
    marginBottom: "1rem",
  },

  priceBox: {
    display: "flex",
    justifyContent: "left",
  },
}))

const OrderDetails = ({ order, user }) => {
  const classes = useStyles()

  return (
    <>
      <Typography variant="h6" align="left">
        {`Order # ${order.id}`}
      </Typography>

      <Typography variant="body1" paragraph>
        {new Date(parseInt(order.orderTime)).toLocaleDateString("en-AU", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })}
      </Typography>
      <Box classes={{ root: classes.oneLine }}>
        <Typography
          variant="body1"
          classes={{ root: classes.firstItemBold }}
        >{`Name: `}</Typography>
        <Typography
          variant="body1"
          classes={{ root: classes.secondItem }}
        >{`${user.userDisplayName}`}</Typography>
      </Box>
      <Box classes={{ root: classes.oneLine }}>
        <Typography
          variant="body1"
          classes={{ root: classes.firstItemBold }}
        >{`Phone: `}</Typography>
        <Typography
          variant="body1"
          classes={{ root: classes.secondItem }}
        >{`${user.phone}`}</Typography>
      </Box>

      <Box classes={{ root: classes.oneLine }}>
        <Typography variant="body1" classes={{ root: classes.firstItemBold }}>
          {`${
            order.orderType === "takeAway"
              ? `Take Away: `
              : order.orderType === "dineIn"
              ? `Dine-In: `
              : order.orderType
          }`}
        </Typography>
        <Typography variant="body1" classes={{ root: classes.secondItem }}>
          {`${
            order.orderType === "takeAway"
              ? `(${order.orderPickUpDate.label}, ${toTime(
                  order.orderPickUpTime
                )})`
              : order.orderType === "dineIn"
              ? `(Table ${order.orderTableNumber})`
              : ``
          }`}
        </Typography>
      </Box>
      <Box classes={{ root: classes.oneLine }}>
        <Typography
          variant="body1"
          classes={{ root: classes.firstItemBold }}
        >{`Order Status: `}</Typography>
        <Typography
          variant="body1"
          classes={{ root: classes.secondItem }}
        >{`${order.status}`}</Typography>
      </Box>
      {order.orderNotes ? (
        <>
          <Typography
            variant="body1"
            classes={{ root: classes.firstItemBold }}
          >{`Order Notes: `}</Typography>
          <Typography
            variant="body1"
            classes={{ root: classes.standard }}
          >{`${order.orderNotes}`}</Typography>
        </>
      ) : (
        <></>
      )}
      <Divider classes={{ root: classes.divider }} />

      {order.items.map((item, indexMain) => (
        <Box classes={{ root: classes.itemBox }} key={indexMain}>
          <Typography
            variant="body1"
            classes={{ root: classes.firstItemBold }}
          >{`${item.qty}x ${item.name}`}</Typography>
          {Object.keys(item.orderSelectedItems).map((objkey, index) =>
            item.orderSelectedItems[objkey].fieldPrintLabel ? (
              <Box key={index} classes={{ root: classes.descriptionTextBox }}>
                <Typography
                  variant="body1"
                  classes={{
                    root: clsx(
                      classes.descriptionText,
                      classes.descriptionTextBold
                    ),
                  }}
                >
                  {`${item.orderSelectedItems[objkey].fieldPrintLabel}`}
                </Typography>

                {item.orderSelectedItems[objkey].selectedItems[0]
                  .optionTextVal ? (
                  <Typography
                    variant="body1"
                    classes={{ root: classes.descriptionText }}
                  >
                    {`${item.orderSelectedItems[objkey].selectedItems[0].optionTextVal}`}
                  </Typography>
                ) : (
                  item.orderSelectedItems[objkey].selectedItems.map(
                    (inSelectedItem, index) => {
                      return (
                        <Typography
                          variant="body1"
                          classes={{ root: classes.descriptionText }}
                          key={index}
                        >
                          {`${
                            item.orderSelectedItems[objkey].fieldType ===
                              "quantityBox" || inSelectedItem.quantity > 1
                              ? `${inSelectedItem.quantity}x `
                              : ""
                          }${inSelectedItem.optionPrintLabel}${
                            index + 1 <
                            item.orderSelectedItems[objkey].selectedItems.length
                              ? `, `
                              : ""
                          }`}
                        </Typography>
                      )
                    }
                  )
                )}
              </Box>
            ) : null
          )}
          <Box classes={{ root: classes.priceBox }}>
            {item.itemRefundAmount && item.itemRefundAmount > 0 ? (
              <Typography
                variant="body1"
                classes={{ root: classes.priceCrossed }}
              >
                {priceFormat(
                  parseFloat(
                    (
                      item.qty * item.totalItemPrice -
                      (item.itemRefundAmount ? item.itemRefundAmount : 0)
                    ).toFixed(2)
                  ),
                  null,
                  true
                )}
              </Typography>
            ) : (
              <></>
            )}
            <Typography variant="body1" classes={{ root: classes.price }}>
              {priceFormat(
                parseFloat(
                  (
                    item.qty * item.totalItemPrice -
                    (item.itemRefundAmount ? item.itemRefundAmount : 0)
                  ).toFixed(2)
                ),
                null,
                true
              )}
            </Typography>
          </Box>
        </Box>
      ))}
      <Divider classes={{ root: classes.divider }} />
      <Box classes={{ root: classes.oneLine }}>
        <Typography
          variant="body1"
          classes={{ root: classes.firstItemBold }}
        >{`Subtotal (inc GST): `}</Typography>
        {order.refundSubtotal && order.refundSubtotal > 0 ? (
          <Typography
            variant="body1"
            classes={{ root: classes.secondItemCrossed }}
          >{`${priceFormat(order.subtotal, null, true)}`}</Typography>
        ) : (
          <></>
        )}
        <Typography
          variant="body1"
          classes={{ root: classes.secondItem }}
        >{`${priceFormat(
          parseFloat(
            (
              order.subtotal - (order.refundSubtotal ? order.refundSubtotal : 0)
            ).toFixed(2)
          ),
          null,
          true
        )}`}</Typography>
      </Box>
      {order.serviceFee ? (
        <Box classes={{ root: classes.oneLine }}>
          <Typography
            variant="body1"
            classes={{ root: classes.firstItemBold }}
          >{`Service Fee (inc GST): `}</Typography>

          {order.refundServiceFee && order.refundServiceFee > 0 ? (
            <Typography
              variant="body1"
              classes={{ root: classes.secondItemCrossed }}
            >{`${priceFormat(order.serviceFee, null, true)}`}</Typography>
          ) : (
            <></>
          )}
          <Typography
            variant="body1"
            classes={{ root: classes.secondItem }}
          >{`${priceFormat(
            parseFloat(
              (
                order.serviceFee -
                (order.refundServiceFee ? order.refundServiceFee : 0)
              ).toFixed(2)
            ),
            null,
            true
          )}`}</Typography>
        </Box>
      ) : (
        <></>
      )}

      {order.phSurchargeFee ? (
        <Box classes={{ root: classes.oneLine }}>
          <Typography
            variant="body1"
            classes={{ root: classes.firstItemBold }}
          >{`Public Holiday Surcharge (inc GST): `}</Typography>

          {order.refundPhSurchargeFee && order.refundPhSurchargeFee > 0 ? (
            <Typography
              variant="body1"
              classes={{ root: classes.secondItemCrossed }}
            >{`${priceFormat(order.phSurchargeFee, null, true)}`}</Typography>
          ) : (
            <></>
          )}
          <Typography
            variant="body1"
            classes={{ root: classes.secondItem }}
          >{`${priceFormat(
            parseFloat(
              (
                order.phSurchargeFee -
                (order.refundPhSurchargeFee ? order.refundPhSurchargeFee : 0)
              ).toFixed(2)
            ),
            null,
            true
          )}`}</Typography>
        </Box>
      ) : (
        <></>
      )}

      {order.satSurchargeRate ? (
        <Box classes={{ root: classes.oneLine }}>
          <Typography
            variant="body1"
            classes={{ root: classes.firstItemBold }}
          >{`Weekend Surcharge (inc GST): `}</Typography>

          {order.refundSatSurchargeFee && order.refundSatSurchargeFee > 0 ? (
            <Typography
              variant="body1"
              classes={{ root: classes.secondItemCrossed }}
            >{`${priceFormat(order.satSurchargeRate, null, true)}`}</Typography>
          ) : (
            <></>
          )}
          <Typography
            variant="body1"
            classes={{ root: classes.secondItem }}
          >{`${priceFormat(
            parseFloat(
              (
                order.satSurchargeRate -
                (order.refundSatSurchargeFee ? order.refundSatSurchargeFee : 0)
              ).toFixed(2)
            ),
            null,
            true
          )}`}</Typography>
        </Box>
      ) : (
        <></>
      )}

      {order.sunSurchargeRate ? (
        <Box classes={{ root: classes.oneLine }}>
          <Typography
            variant="body1"
            classes={{ root: classes.firstItemBold }}
          >{`Weekend Surcharge (inc GST): `}</Typography>

          {order.refundSunSurchargeFee && order.refundSunSurchargeFee > 0 ? (
            <Typography
              variant="body1"
              classes={{ root: classes.secondItemCrossed }}
            >{`${priceFormat(order.sunSurchargeRate, null, true)}`}</Typography>
          ) : (
            <></>
          )}
          <Typography
            variant="body1"
            classes={{ root: classes.secondItem }}
          >{`${priceFormat(
            parseFloat(
              (
                order.sunSurchargeRate -
                (order.refundSunSurchargeFee ? order.refundSunSurchargeFee : 0)
              ).toFixed(2)
            ),
            null,
            true
          )}`}</Typography>
        </Box>
      ) : (
        <></>
      )}

      <Box classes={{ root: classes.oneLine }}>
        <Typography
          variant="body1"
          classes={{ root: classes.firstItemBold }}
        >{`GST: `}</Typography>
        <Typography
          variant="body1"
          classes={{ root: classes.secondItem }}
        >{`${priceFormat(order.tax, null, true)}`}</Typography>
      </Box>
      {order.refundAmount && order.refundAmount > 0 ? (
        <Box classes={{ root: classes.oneLine }}>
          <Typography
            variant="body1"
            classes={{ root: classes.firstItemBold }}
          >{`Refund: `}</Typography>
          <Typography
            variant="body1"
            classes={{ root: classes.secondItemBold }}
          >{` - ${priceFormat(order.refundAmount, null, true)}`}</Typography>
        </Box>
      ) : (
        <></>
      )}
      <Typography
        variant="body1"
        classes={{ root: classes.firstItemBoldTotal }}
      >
        {`Total (inc GST)`}
      </Typography>
      <Box classes={{ root: classes.priceBox }}>
        {order.refundAmount && order.refundAmount > 0 ? (
          <Typography
            variant="body1"
            classes={{ root: classes.secondItemTotalCrossed }}
          >{`${priceFormat(order.total, null, true)}`}</Typography>
        ) : (
          <></>
        )}
        <Typography
          variant="body1"
          classes={{ root: classes.secondItemTotal }}
        >{`${priceFormat(
          (order.total - (order.refundAmount ? order.refundAmount : 0)).toFixed(
            2
          ),
          null,
          true
        )}`}</Typography>
      </Box>
    </>
  )
}

export default OrderDetails
