import * as React from "react"
import { useReactToPrint } from "react-to-print"
import html2pdf from "html2pdf.js"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import OrderDetails from "./OrderDetails"
import { OrderPrint } from "./OrderPrint"

import CloseIcon from "@mui/icons-material/Close"
import CircularProgress from "@material-ui/core/CircularProgress"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  dialogTitCont: {
    padding: "1rem",
    backgroundColor: theme.palette.common.almostWhite,
  },
  dialogContent: {
    textAlign: "left",
    minHeight: "5rem",
    backgroundColor: theme.palette.common.white,
    padding: "1rem",
  },

  taxInvTit: {
    fontSize: "1.5rem",
  },

  butText: {
    fontSize: "1.2rem",
  },

  but: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },

  xBut: {
    backgroundColor: theme.palette.common.offWhite,
  },
  circularProgress: {
    verticalAlign: "middle",
  },
  butGrid: {
    padding: "0.75rem",
  },
}))

const OrderDialogCont = ({ order, setOpen, user, storeInfo }) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const componentRef = React.useRef()

  const classes = useStyles()
  const storeName = storeInfo.nameShort.replace(/\s/g, "")
  const handleDownload = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${storeName}_Order_${order.id}.pdf`,
    onBeforePrint: () => setIsLoading(true),
    onAfterPrint: () => setIsLoading(false),
    print: async printIframe => {
      const document = printIframe.contentDocument
      if (document) {
        const html = document.getElementsByTagName("html")[0]
        let options = {
          margin: [10, 10],
          filename: `${storeName}_Order_${order.id}.pdf`,
          pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        }
        html2pdf().set(options).from(html.innerHTML).toPdf().save()
      }
    },
  })

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${storeName}_Order_${order.id}`,
    onBeforePrint: () => setIsLoading(true),
    onAfterPrint: () => setIsLoading(false),
    pageStyle: `padding: "1rem"`,
  })

  return (
    <>
      <DialogTitle
        id="order-Dialog-title"
        classes={{ root: classes.dialogTitCont }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" align="left">
              {`Order Summary`}
            </Typography>
            <Typography variant="body1" align="left">
              {`Tax Invoice`}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                setOpen(false)
              }}
              aria-label="close"
              color="primary"
              classes={{
                root: classes.xBut,
              }}
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <OrderDetails order={order} storeInfo={storeInfo} user={user} />
        <OrderPrint
          order={order}
          storeInfo={storeInfo}
          user={user}
          ref={componentRef}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsArea }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={5} classes={{ root: classes.butGrid }}>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={handlePrint}
              disableElevation
              fullWidth
              disabled={isLoading}
              classes={{ root: classes.but }}
            >
              {isLoading ? (
                <CircularProgress
                  size="1.5rem"
                  classes={{ root: classes.circularProgress }}
                />
              ) : (
                <Typography variant="body2" classes={{ root: classes.butText }}>
                  {"Print"}
                </Typography>
              )}
            </Button>
          </Grid>
          <Grid item xs={7} classes={{ root: classes.butGrid }}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleDownload}
              disableElevation
              fullWidth
              disabled={isLoading}
              classes={{ root: classes.but }}
            >
              {isLoading ? (
                <CircularProgress
                  size="1.5rem"
                  classes={{ root: classes.circularProgress }}
                />
              ) : (
                <Typography variant="body2" classes={{ root: classes.butText }}>
                  {"Download"}
                </Typography>
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  )
}

export default OrderDialogCont
