import * as React from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import InputAdornment from "@material-ui/core/InputAdornment"
import CircularProgress from "@material-ui/core/CircularProgress"

import { setUser } from "../../contexts/actions"

import Typography from "@material-ui/core/Typography"

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"

import Field from "../auth/field"
import handleError from "../../helperFunc/handleError"
import handleSuccess from "../../helperFunc/handleSuccess"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: { width: "100%" },
  accountIcon: { fontSize: "4rem !important" },
  fieldGrid: { marginTop: "1rem" },
  formMainButtonCont: {
    marginTop: "1.5rem",
    marginBottom: "1rem",
    textAlign: "center",
  },

  formMainButton: {
    padding: "1rem",
  },

  formStyle: {
    minWidth: "18rem",

    [theme.breakpoints.down("xs")]: {
      minWidth: "95%",
    },
  },

  "@global": {
    ".forgotPasswordTextStripped": {
      cursor: "pointer",
      textDecorationLine: "underline",
      color: theme.palette.common.grey,
    },
  },
}))

const AccountDetails = ({ user, dispatchUser, dispatchFeedback }) => {
  const [loading, setLoading] = React.useState(false)
  const [isChanged, setIsChanged] = React.useState(false)

  const classes = useStyles()
  const { handleSubmit, control, formState, watch } = useForm({
    mode: "onChange",
  })
  const { isValid } = formState
  let { name, receiptEmail } = watch()

  const onSubmit = data => {
    setLoading(true)
    axios
      .post(
        process.env.GATSBY_STRAPI_URL + "/api/users/update-by-phone",
        {
          userDisplayName: data.name.replace(/^\s+|\s+$/gm, ""),
          receiptEmail: data.receiptEmail.replace(/^\s+|\s+$/gm, ""),
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        setLoading(false)
        dispatchUser(
          setUser({
            ...response.data,
            jwt: user.jwt,
            onboarding: true,
          })
        )
        handleSuccess("Account successfully updated.", dispatchFeedback)
      })
      .catch(error => {
        setLoading(false)
        handleError(error, dispatchFeedback)
      })
  }

  React.useEffect(() => {
    if (receiptEmail !== user.receiptEmail || name !== user.userDisplayName) {
      setIsChanged(true)
    } else {
      setIsChanged(false)
    }
  }, [receiptEmail, name, user.receiptEmail, user.userDisplayName])

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="center"
      classes={{ root: classes.mainContainer }}
    >
      <Grid item classes={{ root: classes.formStyle }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item classes={{ root: classes.fieldGrid }}>
            <Field
              fieldDefaultValue={user.userDisplayName}
              fieldName={"name"}
              fieldControl={control}
              fieldQuickCheck={true}
              fieldInstCheck={true}
              fieldInstHelperText={"Your name is required"}
              fieldRules={{
                required: {
                  value: true,
                  message: "Your name is required",
                },
                maxLength: {
                  value: 70,
                  message: "Name is too long",
                },
                minLength: {
                  value: 2,
                  message: "Name is too short",
                },
              }}
              fieldPlaceholder={"Name *"}
              fieldStartAdornment={
                <InputAdornment position="start">
                  <AccountCircleOutlinedIcon />
                </InputAdornment>
              }
            ></Field>
          </Grid>
          <Grid item classes={{ root: classes.fieldGrid }}>
            <Field
              fieldDefaultValue={user.receiptEmail}
              fieldName={"receiptEmail"}
              fieldControl={control}
              fieldRules={{
                validate: {
                  value: value => {
                    const emailRegex = new RegExp(/^\S+@\S+\.\S+$/)
                    if (emailRegex.test(value.replace(/^\s+|\s+$/gm, ""))) {
                      return true
                    } else if (value.length < 1) {
                      return true
                    } else {
                      return "Invalid email"
                    }
                  },
                },
                maxLength: {
                  value: 320,
                  message: "Email address is too long",
                },
              }}
              fieldPlaceholder={"Email"}
              fieldStartAdornment={
                <InputAdornment position="start">
                  <EmailOutlinedIcon />
                </InputAdornment>
              }
            ></Field>
          </Grid>

          <Grid item classes={{ root: classes.formMainButtonCont }}>
            <Button
              disableElevation
              disabled={loading || !isValid || !isChanged}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              classes={{
                root: classes.formMainButton,
              }}
            >
              {loading ? (
                <CircularProgress size="1.5rem" />
              ) : (
                <Typography variant="body1">{"Save"}</Typography>
              )}
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default AccountDetails
