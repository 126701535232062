import * as React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

import OrderDetails from "./OrderDetails"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainBox: { display: "none" },

  oneLine: { display: "flex" },
  firstItemBold: {
    lineHeight: "1.1rem",
    fontWeight: 700,
    fontSize: "0.9rem",
  },

  secondItem: {
    lineHeight: "1.1rem",
    marginLeft: "0.5rem",
    fontSize: "0.9rem",
  },

  standard: {
    lineHeight: "1.1rem",
    fontSize: "0.9rem",
  },

  standardBold: {
    lineHeight: "1.1rem",
    fontSize: "0.9rem",
    fontWeight: 700,
  },

  storeDetailsBox: {
    marginBottom: "1rem",
  },
}))

export const OrderPrint = React.forwardRef(
  ({ order, storeInfo, user }, ref) => {
    const classes = useStyles()

    return (
      <Box classes={{ root: classes.mainBox }}>
        <div ref={ref}>
          <Box classes={{ root: classes.storeDetailsBox }}>
            <Typography variant="h6">{`TAX INVOICE`}</Typography>
            <Typography
              variant="body1"
              classes={{ root: classes.standardBold }}
            >{`${storeInfo.nameLong}`}</Typography>
            <Typography
              variant="body1"
              classes={{ root: classes.standard }}
            >{`${storeInfo.abn}`}</Typography>
            <Box classes={{ root: classes.oneLine }}>
              <Typography
                variant="body1"
                classes={{ root: classes.firstItemBold }}
              >{`P: `}</Typography>
              <Typography
                variant="body1"
                classes={{ root: classes.secondItem }}
              >{`${storeInfo.phone}`}</Typography>
            </Box>
            <Box classes={{ root: classes.oneLine }}>
              <Typography
                variant="body1"
                classes={{ root: classes.firstItemBold }}
              >{`A: `}</Typography>
              <Typography
                variant="body1"
                classes={{ root: classes.secondItem }}
              >{`${storeInfo.address}`}</Typography>
            </Box>
            <Box classes={{ root: classes.oneLine }}>
              <Typography
                variant="body1"
                classes={{ root: classes.firstItemBold }}
              >{`W: `}</Typography>
              <Typography
                variant="body1"
                classes={{ root: classes.secondItem }}
              >{`${storeInfo.siteUrl}`}</Typography>
            </Box>
          </Box>
          <OrderDetails order={order} user={user}></OrderDetails>
        </div>
      </Box>
    )
  }
)
