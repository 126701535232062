import * as React from "react"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import DeleteCard from "./DeleteCard"

import amex from "../../images/cardIcons/amex.svg"
import diners_club from "../../images/cardIcons/diners_club.svg"
import discover from "../../images/cardIcons/discover.svg"
import elo from "../../images/cardIcons/elo.svg"
import jcb from "../../images/cardIcons/jcb.svg"
import mastercard from "../../images/cardIcons/mastercard.svg"
import unionpay from "../../images/cardIcons/unionpay.svg"
import visa from "../../images/cardIcons/visa.svg"
import otherCard from "../../images/cardIcons/otherCard.svg"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  togButText: {
    textTransform: "none !important",
    fontWeight: "500 !important",
    marginRight: "0.5rem",
  },

  togButTextYear: {
    textTransform: "none !important",
    fontWeight: "400 !important",
  },
  standardBox: {
    display: "flex",

    fontSize: "1rem !important",
    lineHeight: "0.5rem !important",
    height: "4.5rem !important",
  },

  iconGrid: {
    paddingRight: "0.75rem",
  },
}))

const cardIcons = {
  amex: { icon: amex, name: "AMEX" },
  diners_club: { icon: diners_club, name: "DINERS CLUB" },
  discover: { icon: discover, name: "DISCOVER" },
  elo: { icon: elo, name: "ELO" },
  jcb: { icon: jcb, name: "JCB" },
  mastercard: { icon: mastercard, name: "MASTERCARD" },
  unionpay: { icon: unionpay, name: "UNIONPAY" },
  visa: { icon: visa, name: "VISA" },
  other: { icon: otherCard, name: "CARD" },
}

const UserSavedCards = ({
  user,
  existingCards,
  setExistingCards,
  dispatchFeedback,
}) => {
  const classes = useStyles()

  return (
    <>
      {existingCards.map((card, index) => {
        return (
          <Box key={index}>
            <Box
              classes={{
                root: classes.standardBox,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item classes={{ root: classes.iconGrid }}>
                      <img
                        src={
                          cardIcons[card.card.brand]
                            ? cardIcons[card.card.brand].icon
                            : cardIcons.other.icon
                        }
                        alt={
                          cardIcons[card.card.brand]
                            ? cardIcons[card.card.brand].name
                            : cardIcons.other.name
                        }
                      ></img>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        classes={{ root: classes.togButText }}
                      >{`●●●● ${card.card.last4}`}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        classes={{ root: classes.togButTextYear }}
                      >{`${card.card.exp_month}/${card.card.exp_year}`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <DeleteCard
                    existingCards={existingCards}
                    setExistingCards={setExistingCards}
                    dispatchFeedback={dispatchFeedback}
                    user={user}
                    card={card}
                  />
                </Grid>
              </Grid>
            </Box>
            {index < existingCards.length - 1 ? <Divider /> : <></>}
          </Box>
        )
      })}
    </>
  )
}

export default UserSavedCards
