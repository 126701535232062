import React from "react"
import { Controller } from "react-hook-form"
import ReactPhoneInput from "react-phone-input-material-ui"
import "react-phone-input-material-ui/lib/style.css"
import { TextField, InputBase, Grid, withStyles } from "@material-ui/core"

const styles = theme => ({
  field: {
    margin: "10px 0",
  },

  code: {
    margin: 0,
  },

  flagDisabled: {
    display: "none",
  },
  countryList: {
    ...theme.typography.body1,
  },
})

function PhoneField({
  classes,
  fieldControl,
  fieldDefaultValue,
  fieldName,
  fieldStartAdornment,
  fieldEndAdornment,
  fieldHelperText,
  fieldStyle,
  fieldQuickCheck,
  fieldCodeStyle,
  fieldInpStyle,
  country,
  setCountry,
  setFormattedPhone,
  resetField,
  fieldAutoFocus,
}) {
  const [isTouched, setIsTouched] = React.useState(false)

  const handleOnChange = countryIn => {
    if (country.countryCode !== countryIn.countryCode) {
      resetField(fieldName)
      setCountry(countryIn)
    }
  }

  const handlePhoneOnChange = formattedValue => {
    setFormattedPhone(formattedValue)
  }

  return (
    <Controller
      name={fieldName}
      control={fieldControl}
      defaultValue={fieldDefaultValue ? fieldDefaultValue : ""}
      rules={{
        required: {
          value: true,
          message: "Mobile number required",
        },
        validate: {
          value: value => {
            if (country.countryCode === "au" && value.length >= 9) {
              return true
            } else if (country.countryCode !== "au" && value.length >= 4) {
              return true
            } else {
              return "Invalid mobile number"
            }
          },
        },
      }}
      render={({
        field: { value, onChange, ref },
        fieldState: { error, invalid },
      }) => (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <ReactPhoneInput
              onChange={(value, country, e, formattedValue) => {
                handleOnChange(country)
              }}
              country={"au"}
              enableSearch={true}
              masks={{ au: "... ... ..." }}
              countryCodeEditable={false}
              disableCountryCode={false}
              disableSearchIcon={true}
              disableCountryGuess={true}
              inputClass={classes.code}
              dropdownClass={classes.countryList}
              component={InputBase}
              inputProps={{
                classes: fieldCodeStyle ? fieldCodeStyle : undefined,
                disabled: true,
                fullWidth: false,
                autoFocus: true,
                label: "",
                type: "tel",
                variant: "outlined",
                error: invalid && isTouched ? true : false,
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <ReactPhoneInput
              value={value}
              onChange={(value, country, e, formattedValue) => {
                let valueCopy = value
                if (
                  valueCopy.length > 4 &&
                  valueCopy[0] === "0" &&
                  country.countryCode === "au"
                ) {
                  valueCopy = valueCopy.slice(1, valueCopy.length)
                }
                onChange(valueCopy)
                if (setFormattedPhone) {
                  handlePhoneOnChange(formattedValue)
                }
              }}
              disableCountryCode={true}
              disableCountryGuess={true}
              country={country.countryCode}
              masks={{ au: "... ... ..." }}
              defaultValue={""}
              placeholder={
                country.countryCode === "au" ? "4XX XXX XXX" : "Mobile number"
              }
              onBlur={() => {
                if (fieldQuickCheck || value.length > 0) {
                  setIsTouched(true)
                }
              }}
              buttonClass={classes.flagDisabled}
              dropdownClass={classes.countryList}
              component={TextField}
              inputProps={{
                InputProps: {
                  startAdornment: fieldStartAdornment
                    ? fieldStartAdornment
                    : undefined,
                  endAdornment: fieldEndAdornment
                    ? fieldEndAdornment
                    : undefined,
                  classes: fieldInpStyle ? fieldInpStyle : undefined,
                },
                classes: fieldStyle ? fieldStyle : undefined,

                fullWidth: false,
                autoFocus: fieldAutoFocus,
                label: "",
                type: "tel",
                variant: "outlined",
                error: invalid && isTouched ? true : false,
                helperText:
                  error && isTouched
                    ? error?.message
                    : fieldHelperText
                    ? fieldHelperText
                    : "",
              }}
            />
          </Grid>
        </Grid>
      )}
    ></Controller>
  )
}

export default withStyles(styles)(PhoneField)
