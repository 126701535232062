import * as React from "react"
import clsx from "clsx"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import CardActions from "@material-ui/core/CardActions"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined"
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined"
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { makeStyles } from "@material-ui/core/styles"

import AccountDetails from "./AccountDetails"
import PaymentDetails from "./PaymentDetails"
import Orders from "./Orders"

const useStyles = makeStyles(theme => ({
  mainIcon: {
    verticalAlign: "sub",
    fontSize: "2rem !important",
    marginRight: "0.5rem",
  },

  subIcon: {
    verticalAlign: "bottom",
    fontSize: "1.7rem !important",
    marginRight: "0.5rem",
  },

  headerContainer: {
    backgroundColor: theme.palette.common.almostWhite,
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    marginBottom: "0.5rem",
  },
  headerContainerSelected: {
    backgroundColor: theme.palette.common.offWhite,
  },

  detailsContainer: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },

  headerText: {
    fontSize: "1.2rem",
  },

  inMainContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: "4.0rem",
    marginBottom: "5rem",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "40rem",
      marginTop: "6.0rem",
      marginBottom: "2rem",
    },
  },
  boxGrids: {
    padding: 0,
  },
  mainCard: {
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      borderRadius: "1rem",
    },
  },
  titleArea: {
    backgroundColor: theme.palette.common.darkGrey,
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem !important",
  },
  titleText: {
    textTransform: "uppercase",
    fontSize: "1.5rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    color: theme.palette.common.white,
  },

  contentArea: {
    padding: 0,
    paddingBottom: "0 !important",

    backgroundColor: theme.palette.common.white,
  },

  bottomArea: {
    backgroundColor: theme.palette.common.offWhite,
    height: "0px",
    padding: "0px",
  },

  contentText: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    lineHeight: "1.2rem",
  },

  innerContainer: {
    width: "100%",
  },
  mainContainer: {
    minHeight: "calc(100vh  - 66px)",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  "@global": {
    body: {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: `${theme.palette.common.white} !important`,
      },
    },
  },
}))

const UserPanel = ({
  user,
  dispatchUser,
  dispatchFeedback,
  feedback,
  storeSettings,
}) => {
  const [expanded, setExpanded] = React.useState("orders")

  const classes = useStyles()

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const steps = [
    {
      component: AccountDetails,
      icon: <AccountBoxOutlinedIcon classes={{ root: classes.subIcon }} />,
      label: "Account Details",
      slug: "account-details",
      isOpen: false,
    },
    {
      component: PaymentDetails,
      icon: <PaymentOutlinedIcon classes={{ root: classes.subIcon }} />,
      label: "Payment Details",
      slug: "payment-details",
      isOpen: false,
    },
    {
      component: Orders,
      icon: <LibraryAddCheckOutlinedIcon classes={{ root: classes.subIcon }} />,
      label: "Orders",
      slug: "orders",
      isOpen: true,
    },
  ]

  //console.log("LOGEDIN_USER", user)

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      classes={{ root: classes.mainContainer }}
    >
      <Grid item classes={{ root: classes.innerContainer }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Container classes={{ root: classes.inMainContainer }}>
            <Grid item classes={{ root: classes.boxGrids }}>
              <Card classes={{ root: classes.mainCard }} elevation={0}>
                <CardContent classes={{ root: classes.titleArea }}>
                  <Typography
                    variant="h6"
                    classes={{ root: classes.titleText }}
                  >
                    {"My Account"}
                  </Typography>
                </CardContent>
                <CardActions
                  disableSpacing
                  classes={{ root: classes.bottomArea }}
                ></CardActions>

                <CardContent classes={{ root: classes.contentArea }}>
                  {steps.map((Step, i) => (
                    <Grid key={i} item>
                      <Accordion
                        elevation={0}
                        square
                        expanded={expanded === Step.slug}
                        onChange={handleChange(Step.slug)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`${Step.slug}-content`}
                          id={`${Step.slug}-header`}
                          classes={{
                            root: clsx(classes.headerContainer, {
                              [classes.headerContainerSelected]:
                                expanded === Step.slug,
                            }),
                          }}
                        >
                          <Box>
                            <Typography
                              variant={expanded === Step.slug ? "h6" : "h5"}
                              classes={{ root: classes.headerText }}
                            >
                              {Step.icon}
                              {`${Step.label}`}
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          classes={{ root: classes.detailsContainer }}
                        >
                          <Grid container direction="column">
                            <Step.component
                              user={user}
                              dispatchUser={dispatchUser}
                              dispatchFeedback={dispatchFeedback}
                              feedback={feedback}
                              storeSettings={storeSettings}
                            ></Step.component>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UserPanel
