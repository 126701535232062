import * as React from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import { Link, navigate } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import { setUser } from "../../contexts/actions"
import { default as UiLink } from "@material-ui/core/Link"

import Typography from "@material-ui/core/Typography"

import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined"
import InstallMobileOutlinedIcon from "@mui/icons-material/InstallMobileOutlined"
import PinOutlinedIcon from "@mui/icons-material/PinOutlined"

import Field from "./field"
import PhoneField from "./PhoneField"
import handleError from "../../helperFunc/handleError"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: {},
  accountIcon: {
    fontSize: "4rem !important",
  },
  accountIconFlipped: {
    fontSize: "4rem !important",
    "-webkit-transform": "scaleX(-1)",
  },
  fieldGrid: {
    marginTop: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "18rem",
    },
  },

  codeGrid: {
    textAlign: "center",
    marginTop: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "18rem",
    },
  },
  formMainButtonCont: { marginTop: "2rem", textAlign: "center" },
  forgotPassword: {},
  forgotPasswordText: {
    marginTop: "0.5rem",
    textAlign: "center",
  },

  phoneCodeInput: {
    fontSize: "1rem",
    height: "4rem",
  },
  phoneCodeInputDisabled: {
    cursor: "default !important",
  },
  phoneField: {
    paddingLeft: "1rem",
    height: "4rem",
    width: "100%",
  },
  phoneInput: {
    fontSize: "1.2rem",
    paddingLeft: "1rem !important",
    height: "4rem",
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },

  links: {
    color: theme.palette.common.seventyWhite,
  },

  codeBlocks: {
    display: "inline-grid",
  },

  signUpContainer: { paddingTop: "3rem", textAlign: "center" },

  passwordVisableIcon: { padding: 0 },

  formStyle: {
    minWidth: "18rem",

    [theme.breakpoints.down("xs")]: {
      minWidth: "90%",
    },
  },

  formMainButton: {
    fontSize: "1.4rem !important",
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
    marginBottom: "1.5rem",
    minHeight: "4rem",
  },
  codeInput: {
    fontSize: "1.5rem",
    textAlign: "center",
  },
  codeStyle: {
    width: "3.2rem",
    marginLeft: "0.2rem",
    marginRight: "0.2rem",
  },

  titleText: {
    fontSize: "1.5rem",
  },

  "@global": {
    body: {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: `${theme.palette.common.white} !important`,
      },
    },
    ".MuiLink-underlineAlways": {
      textDecoration: "underline !important",
    },
    ".forgotPasswordTextStripped": {
      cursor: "pointer",
      textDecorationLine: "underline",
      color: theme.palette.common.grey,
    },
  },
}))

const codeLen = 4
const reSendTimer = 61

const Login = ({ user, dispatchUser, dispatchFeedback, location }) => {
  const [isVerifying, setIsVerifying] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [codeError, setCodeError] = React.useState(false)
  const [submittingCode, setSubmittingCode] = React.useState(false)
  const [counter, setCounter] = React.useState(reSendTimer)
  const [codeValue, setCodeValue] = React.useState([...Array(codeLen)].fill(""))
  const [formattedPhone, setFormattedPhone] = React.useState("")
  const [isCheckout, setIsCheckout] = React.useState(false)
  const [country, setCountry] = React.useState({
    countryCode: "au",
    dialCode: "61",
    format: "+.. ... ... ...",
    name: "Australia",
  })

  const classes = useStyles()
  const { handleSubmit, control, formState, getValues, resetField } = useForm({
    mode: "onChange",
  })
  const { isValid } = formState
  const watchedData = getValues()

  const sendSmsReq = (phone, resend) => {
    axios
      .post(process.env.GATSBY_STRAPI_URL + "/api/users/create-by-phone", {
        phone: `+${country.dialCode}${phone}`,
        phoneCode: `+${country.dialCode}`,
      })
      .then(response => {
        setLoading(false)
        resend ? setCounter(reSendTimer) : setIsVerifying(true)
      })
      .catch(error => {
        setLoading(false)
        handleError(error, dispatchFeedback)
      })
  }

  const setIsCheckouTrue = () => {
    setIsCheckout(true)
  }

  React.useEffect(() => {
    if (location.state) {
      if (location.state.isCheckoutStep) {
        setIsCheckouTrue()
      }
    }
  }, [location.state])

  const onSubmit = data => {
    setLoading(true)
    if (!isVerifying) {
      sendSmsReq(data.phone, false)
    } else {
      setLoading(false)
    }
  }

  const sendAgain = () => {
    setLoading(true)
    sendSmsReq(watchedData.phone, true)
  }

  React.useEffect(() => {
    let inValid = codeValue.filter(element => element === "")
    if (inValid.length === 0) {
      setSubmittingCode(true)
    } else {
      setSubmittingCode(false)
    }
  }, [codeValue, setSubmittingCode])

  React.useEffect(() => {
    if (submittingCode) {
      axios
        .post(process.env.GATSBY_STRAPI_URL + "/api/users/verify-by-phone", {
          phone: `+${country.dialCode}${watchedData.phone}`,
          token: codeValue.join(""),
        })
        .then(response => {
          localStorage.removeItem("intentID")
          dispatchUser(
            setUser({
              ...response.data.user,
              jwt: response.data.jwt,
              onboarding: isCheckout ? false : true,
            })
          )
          if (isCheckout) {
            setLoading(false)
            setSubmittingCode(false)
            navigate("/cart/", {
              state: { isCheckoutStep: true },
              replace: true,
            })
          } else {
            setLoading(false)
            setSubmittingCode(false)
          }
        })
        .catch(error => {
          setSubmittingCode(false)
          setLoading(false)
          setCodeError(true)
          setCodeValue([...Array(codeLen)].fill(""))
          handleError(error, dispatchFeedback)
        })
    }
  }, [
    submittingCode,
    codeValue,
    dispatchFeedback,
    dispatchUser,
    watchedData.phone,
    country.dialCode,
    isCheckout,
  ])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (isVerifying && counter > 0) {
        setCounter(counter - 1)
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [counter, isVerifying])

  //console.log("LOGEDIN_USER", user)

  return (
    <>
      <Grid item>
        <PinOutlinedIcon classes={{ root: classes.accountIcon }} />
        <InstallMobileOutlinedIcon
          classes={{ root: classes.accountIconFlipped }}
        />
      </Grid>
      <Grid item classes={{ root: classes.fieldGrid }}>
        <Typography
          variant="h5"
          align={"center"}
          paragraph
          classes={{ root: classes.titleText }}
        >
          {isVerifying
            ? `Verifying +${country.dialCode} ${formattedPhone}`
            : "Verify your mobile number"}
        </Typography>
        <Typography variant="body1" align={"center"}>
          {isVerifying
            ? `Please enter the ${codeLen}-digit code that was sent to your mobile via SMS.`
            : `To verify your number, we'll send you a ${codeLen}-digit code via SMS. You'll only have to do this once.`}
        </Typography>
      </Grid>
      <form
        className={classes.formStyle}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        {isVerifying ? (
          !submittingCode ? (
            <Grid item classes={{ root: classes.codeGrid }}>
              {[...Array(codeLen)].map((e, i) => (
                <Field
                  key={i}
                  index={i}
                  codeValue={codeValue}
                  setCodeValue={setCodeValue}
                  fieldStyle={{ input: classes.codeInput }}
                  codeStyle={{ root: classes.codeStyle }}
                  fieldName={`token-${i}`}
                  displayStyle={{ root: classes.codeBlocks }}
                  fieldType="code"
                  fieldPlaceholder={"-"}
                  isDisabled={submittingCode}
                  codeError={codeError}
                  codeLen={codeLen}
                ></Field>
              ))}
            </Grid>
          ) : (
            <Grid item classes={{ root: classes.codeGrid }}>
              <CircularProgress size="2rem" />
            </Grid>
          )
        ) : (
          <Grid item classes={{ root: classes.fieldGrid }}>
            <PhoneField
              fieldInpStyle={{ input: classes.phoneInput }}
              fieldStyle={{ root: classes.phoneField }}
              fieldCodeStyle={{
                root: classes.phoneCodeInput,
                disabled: classes.phoneCodeInputDisabled,
              }}
              fieldName={"phone"}
              fieldControl={control}
              fieldType="tel"
              fieldPlaceholder={"4XX XXX XXX"}
              country={country}
              setCountry={setCountry}
              setFormattedPhone={setFormattedPhone}
              resetField={resetField}
              fieldAutoFocus={true}
            ></PhoneField>
          </Grid>
        )}

        <Grid item classes={{ root: classes.formMainButtonCont }}>
          {isVerifying ? (
            loading ? (
              <Grid item classes={{ root: classes.codeGrid }}>
                <CircularProgress size="2rem" />
              </Grid>
            ) : (
              <UiLink
                onClick={sendAgain}
                component={Button}
                variant={"body1"}
                disabled={counter > 0}
                underline={counter > 0 ? "none" : "always"}
              >
                {`Resend code`} {counter > 0 ? ` (${counter}s)` : ""}
              </UiLink>
            )
          ) : (
            <Button
              disableElevation
              disabled={loading || !isValid}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              classes={{
                root: classes.formMainButton,
              }}
              fullWidth
              endIcon={loading ? undefined : <ArrowForwardIosOutlinedIcon />}
            >
              {loading ? (
                <CircularProgress size="2rem" />
              ) : (
                <Typography variant="body2">{"Next"}</Typography>
              )}
            </Button>
          )}
        </Grid>
      </form>
      {isVerifying ? (
        <></>
      ) : (
        <Grid item container justifyContent="center" direction="column">
          <Grid item>
            <Typography
              variant="body1"
              align={"center"}
              classes={{ root: classes.links }}
            >
              {"By continuing, you agree to our "}
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              classes={{ root: classes.links }}
              variant="body1"
              align={"center"}
            >
              <UiLink
                component={Link}
                variant="body1"
                color="primary"
                to={`/terms-of-service`}
              >
                {"TERMS OF SERVICE"}
              </UiLink>
              {" and "}
              <UiLink
                component={Link}
                variant="body1"
                color="primary"
                to={`/privacy-policy`}
              >
                {"PRIVACY POLICY"}
              </UiLink>
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Login
