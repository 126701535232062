import * as React from "react"
import axios from "axios"
import clsx from "clsx"
import { useStaticQuery, graphql } from "gatsby"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Dialog from "@material-ui/core/Dialog"
import Chip from "@material-ui/core/Chip"

import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import handleError from "../../helperFunc/handleError"
import priceFormat from "../../helperFunc/priceFormat"
import OrderDialogCont from "./OrderDialogCont"

import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined"
import SdCardAlertOutlinedIcon from "@mui/icons-material/SdCardAlertOutlined"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { makeStyles, useTheme } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "8rem",
    position: "relative",
  },
  noOrdersText: {
    fontSize: "1.25rem",
  },
  smallerText: {
    fontSize: "0.8rem",
  },

  noOrdersIcon: {
    fontSize: "2rem !important",
  },

  inBox: {
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },

  dataBox: { height: "100%" },
  moreDetailsButton: {
    marginLeft: "1rem",
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },

    padding: "0.5rem !important",
  },

  priceAndButCont: {
    marginBottom: 0,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0.5rem",
    },
  },

  circularProgress: {
    verticalAlign: "middle",
  },

  marginTop: {
    marginTop: 0,
    [theme.breakpoints.down("xs")]: {
      marginTop: "0.5rem",
    },
  },
  moreDetailsIcon: {
    fontSize: "1.0rem !important",
    color: theme.palette.common.white,
  },

  divider: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  itemCont: {
    width: "100%",
  },
  marginRight: {
    marginRight: "1rem",
    marginBottom: 0,

    [theme.breakpoints.down("sm")]: {
      marginBottom: "0.5rem",
    },
  },
  priceText: {
    fontSize: "1.25rem",
    marginBottom: 0,
    marginTop: 0,

    [theme.breakpoints.down("xs")]: {
      marginBottom: "0.5rem",
      marginTop: "0.5rem",
    },
  },

  loadMoreButton: {
    marginTop: "2rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },

  xBut: {
    backgroundColor: theme.palette.common.almostWhite,

    marginRight: "1.5rem",
    marginLeft: "1.5rem",
  },

  statusChip: {
    color: theme.palette.common.white,
  },
  statusBoxReceived: {
    backgroundColor: `${theme.palette.common.received} !important`,
  },
  statusBoxPreparing: {
    backgroundColor: `${theme.palette.common.preparing} !important`,
  },
  statusBoxReady: {
    backgroundColor: `${theme.palette.common.ready} !important`,
  },
  statusBoxDone: {
    backgroundColor: `${theme.palette.common.done} !important`,
  },
}))

export default function Orders({ user, dispatchFeedback, storeSettings }) {
  const [orders, setOrders] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [pageNum, setPageNum] = React.useState(0)
  const [loadMore, setLoadMore] = React.useState(false)
  const [loadMoreLoading, setLoadMoreLoading] = React.useState(false)
  const [selectedOrder, setSelectedOrder] = React.useState({})
  const [open, setOpen] = React.useState(false)

  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const recodsPerReq = 5

  const handleClose = () => {
    setOpen(false)
  }

  const data = useStaticQuery(graphql`
    query StoreDetailsQueryMain {
      allStrapiStoreInfo {
        edges {
          node {
            abn
            addressUrl
            backEndUrl
            address
            email
            emailUrl
            nameLong
            nameShort
            phone
            phoneUrl
            siteUrl
          }
        }
      }
    }
  `)

  const storeInfo = data.allStrapiStoreInfo.edges[0].node

  React.useEffect(() => {
    axios
      .post(
        process.env.GATSBY_STRAPI_URL + "/api/orders/history",
        {
          startInd: 0,
          resLimit: recodsPerReq + 1,
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        let ordersToSet = [...response.data.orders]
        if (ordersToSet.length > recodsPerReq) {
          setLoadMore(true)
          setPageNum(1)
          ordersToSet.pop(1)
        }
        setOrders(ordersToSet)
        if (ordersToSet.length > 0) {
          setSelectedOrder(ordersToSet[0])
        }
        setIsLoading(false)
      })
      .catch(error => {
        handleError(error, dispatchFeedback)
        setIsLoading(false)
      })
  }, [dispatchFeedback, user.jwt])

  const handleLoadMore = () => {
    setLoadMoreLoading(true)
    axios
      .post(
        process.env.GATSBY_STRAPI_URL + "/api/orders/history",
        {
          startInd: pageNum * recodsPerReq,
          resLimit: recodsPerReq + 1,
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        let ordersToSet = [...response.data.orders]
        if (ordersToSet.length > recodsPerReq) {
          setLoadMore(true)
          setPageNum(pageNum + 1)
          ordersToSet.pop(1)
        } else {
          setLoadMore(false)
        }
        setOrders([...orders, ...ordersToSet])
        setLoadMoreLoading(false)
      })
      .catch(error => {
        handleError(error, dispatchFeedback)
        setLoadMoreLoading(false)
      })
  }

  return (
    <>
      {isLoading ? (
        <Box classes={{ root: classes.outBox }}>
          <Box classes={{ root: classes.inBox }}>
            <CircularProgress color="primary" />
          </Box>
        </Box>
      ) : orders.length > 0 ? (
        <>
          {orders.map((order, index) => {
            return (
              <Box key={index} classes={{ root: classes.itemCont }}>
                <Box
                  component={Button}
                  classes={{ root: classes.itemCont }}
                  onClick={() => {
                    setSelectedOrder(order)
                    setOpen(true)
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={9}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item classes={{ root: classes.marginRight }}>
                          <Typography variant="h6" align="left">
                            {`# ${order.id}`}
                          </Typography>
                          <Typography variant="body1">
                            {new Date(
                              parseInt(order.orderTime)
                            ).toLocaleDateString("en-AU", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            })}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item classes={{ root: classes.marginRight }}>
                              <Chip
                                label={`${
                                  order.orderType === "takeAway"
                                    ? "Take Away"
                                    : order.orderType === "dineIn"
                                    ? "Dine-In"
                                    : order.orderType
                                }`}
                              />
                            </Grid>
                            <Grid item classes={{ root: classes.marginRight }}>
                              <Chip
                                classes={{
                                  root: clsx(classes.statusChip, {
                                    [classes.statusBoxReceived]:
                                      order.status === "Received",
                                    [classes.statusBoxPreparing]:
                                      order.status === "Preparing",
                                    [classes.statusBoxReady]:
                                      order.status === "Ready",
                                    [classes.statusBoxDone]:
                                      order.status === "Done",
                                  }),
                                }}
                                label={`${order.status}`}
                              ></Chip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      classes={{ root: classes.priceAndButCont }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography
                            variant="h5"
                            classes={{ root: classes.priceText }}
                          >
                            {`${priceFormat(order.total)}`}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            component={Box}
                            classes={{ root: classes.moreDetailsButton }}
                          >
                            <ArrowForwardIosOutlinedIcon
                              classes={{ root: classes.moreDetailsIcon }}
                            ></ArrowForwardIosOutlinedIcon>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                {index < orders.length - 1 ? (
                  <Divider classes={{ root: classes.divider }} />
                ) : (
                  <></>
                )}
              </Box>
            )
          })}
          {loadMore ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoadMore}
              size="large"
              fullWidth
              disableElevation
              disabled={!loadMore || loadMoreLoading}
              classes={{ root: classes.loadMoreButton }}
            >
              {loadMoreLoading ? (
                <CircularProgress
                  size="1.5rem"
                  classes={{ root: classes.circularProgress }}
                />
              ) : (
                <Typography variant="body1">{"Show More Orders"}</Typography>
              )}
            </Button>
          ) : (
            <></>
          )}
        </>
      ) : (
        <Box classes={{ root: classes.outBox }}>
          <Box classes={{ root: classes.inBox }}>
            <SdCardAlertOutlinedIcon classes={{ root: classes.noOrdersIcon }} />
            <Typography
              variant="h5"
              classes={{ root: classes.gridIconSeparator }}
            >
              {"No orders found"}
            </Typography>
          </Box>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        fullScreen={fullScreen}
        maxWidth={"xs"}
        fullWidth
        aria-labelledby="order-details-title"
        aria-describedby="order-details-description"
        classes={{
          paperFullWidth: classes.dialogPaperFullWidth,
          paper: classes.dialogPaper,
          paperScrollPaper: classes.dialogPaperScrollPaper,
        }}
      >
        <OrderDialogCont
          order={selectedOrder}
          setOpen={setOpen}
          storeSettings={storeSettings}
          user={user}
          storeInfo={storeInfo}
        />
      </Dialog>
    </>
  )
}
