import * as React from "react"
import axios from "axios"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Box from "@material-ui/core/Box"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import CircularProgress from "@material-ui/core/CircularProgress"

import handleError from "../../helperFunc/handleError"
import handleSuccess from "../../helperFunc/handleSuccess"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  alertTitCont: {
    paddingBottom: 0,
  },

  alertTitle: { fontSize: "1.5rem !important", marginTop: "0.5rem" },
  butGridCont: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingBottom: "1rem",
  },

  butGrid: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  but: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },

  butRemove: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    backgroundColor: `${theme.palette.secondary.light} !important`,
    color: `${theme.palette.common.white} !important`,
    "&:hover": {
      backgroundColor: `${theme.palette.secondary.dark} !important`,
    },
  },
}))

const DeleteCard = ({
  existingCards,
  user,
  setExistingCards,
  dispatchFeedback,
  card,
}) => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleRemove = () => {
    setOpen(false)
    setLoading(true)
    axios
      .post(
        process.env.GATSBY_STRAPI_URL + "/api/orders/detachPaymentMethod",
        {
          PaymentMethodId: card.id,
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        setLoading(false)
        handleSuccess("Card details successfully removed.", dispatchFeedback)
        let existingCardsCopy = existingCards.filter(
          cardIn => cardIn.id !== card.id
        )
        setExistingCards(existingCardsCopy)
      })
      .catch(error => {
        setLoading(false)
        handleError(error, dispatchFeedback)
      })
  }

  const classes = useStyles()
  return (
    <>
      {loading ? (
        <CircularProgress size="1.5rem" />
      ) : (
        <Button
          disableElevation
          disabled={loading}
          color={"primary"}
          variant="contained"
          size="small"
          onClick={e => {
            setOpen(true)
          }}
        >
          <Typography variant="body1">{"DELETE"}</Typography>
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        fullWidth
        aria-labelledby="alert-remove-item-title"
        aria-describedby="alert-remove-item-description"
      >
        <DialogTitle
          id="alert-remove-item-title"
          classes={{ root: classes.alertTitCont }}
        >
          <Box classes={{ root: classes.alertTitle }}>{"Remove card?"}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-remove-item-description">
            {"Are you sure you want to remove this card?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            classes={{ root: classes.butGridCont }}
          >
            <Grid item xs={6} classes={{ root: classes.butGrid }}>
              <Button
                onClick={handleClose}
                variant="outlined"
                size="large"
                color="primary"
                disableElevation
                fullWidth
                classes={{ root: classes.but }}
              >
                <Typography variant="body1">{"Cancel"}</Typography>
              </Button>
            </Grid>
            <Grid item xs={6} classes={{ root: classes.butGrid }}>
              <Button
                onClick={handleRemove}
                variant="contained"
                size="large"
                disableElevation
                fullWidth
                classes={{ root: classes.butRemove }}
              >
                <Typography variant="body1">{"Remove"}</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteCard
