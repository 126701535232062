import * as React from "react"
import axios from "axios"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import handleError from "../../helperFunc/handleError"
import UserSavedCards from "./UserSavedCards"

import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "8rem",
    position: "relative",
  },
  noCardsText: {
    fontSize: "1.25rem",
  },
  smallerText: {
    fontSize: "0.8rem",
  },

  noCardsIcon: {
    fontSize: "2rem !important",
  },

  inBox: {
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}))

export default function PaymentDetails({ user, dispatchFeedback }) {
  const [existingCards, setExistingCards] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  const classes = useStyles()

  React.useEffect(() => {
    axios
      .get(process.env.GATSBY_STRAPI_URL + "/api/orders/getPaymentMethods", {
        headers: {
          Authorization: `Bearer ${user.jwt}`,
        },
      })
      .then(response => {
        setExistingCards(response.data)
        setIsLoading(false)
      })
      .catch(error => {
        handleError(error, dispatchFeedback)
        setIsLoading(false)
      })
  }, [dispatchFeedback, user.jwt])

  return isLoading ? (
    <Box classes={{ root: classes.outBox }}>
      <Box classes={{ root: classes.inBox }}>
        <CircularProgress color="primary" />
      </Box>
    </Box>
  ) : existingCards.length > 0 ? (
    <UserSavedCards
      existingCards={existingCards}
      setExistingCards={setExistingCards}
      dispatchFeedback={dispatchFeedback}
      user={user}
    />
  ) : (
    <Box classes={{ root: classes.outBox }}>
      <Box classes={{ root: classes.inBox }}>
        <CreditCardOffOutlinedIcon classes={{ root: classes.noCardsIcon }} />
        <Typography variant="h5" classes={{ root: classes.noCardsText }}>
          {"No saved cards found"}
        </Typography>
        <Typography variant="body1" classes={{ root: classes.smallerText }}>
          {"You can save your payment details at checkout."}
        </Typography>
      </Box>
    </Box>
  )
}
