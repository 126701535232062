import * as React from "react"
import Layout from "../components/ui/layout"
import Seo from "../components/ui/seo"
import AuthPortal from "../components/auth/AuthPortal"
import UserPanel from "../components/user/UserPanel"
import { refreshClosedStoreSettings } from "../contexts/actions"

import { UserContext, FeedbackContext, StoreSettingsContext } from "../contexts"

const Account = ({ location }) => {
  const { user, dispatchUser, defaultUser } = React.useContext(UserContext)
  const { feedback, dispatchFeedback } = React.useContext(FeedbackContext)
  const { storeSettings, dispatchStoreSettings } =
    React.useContext(StoreSettingsContext)

  React.useEffect(() => {
    dispatchStoreSettings(refreshClosedStoreSettings())
  }, [dispatchStoreSettings])

  return (
    <Layout footerOne={false} footerTwo={true}>
      <Seo title="Account" description="My account page" />
      {user.jwt && user.onboarding ? (
        <UserPanel
          user={user}
          dispatchUser={dispatchUser}
          defaultUser={defaultUser}
          feedback={feedback}
          dispatchFeedback={dispatchFeedback}
          storeSettings={storeSettings}
          dispatchStoreSettings={dispatchStoreSettings}
          location={location}
        />
      ) : (
        <AuthPortal location={location} />
      )}
    </Layout>
  )
}

export default Account
